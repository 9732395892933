<template>
    <div style="text-align: left;" class="detail">
        <el-tabs>
            <el-tab-pane :label="type==1?'雷达详情':'线索详情'" name="详情"></el-tab-pane>
        </el-tabs>
        <template v-if="UserRecordEventList && UserRecordEventList[0]">
            <div class="top">
                <div class="top_left">
                    <img width="45px" height="45px" class="circle" :src="$oucy.ossUrl +UserRecordEventList[0].operationUserAvata" v-if="UserRecordEventList[0].operationUserAvata"></img>
                    <img width="45px" height="45px" class="circle" src="https://aliyunoss.lesoujia.com/lsj_mobile/image/Live/visitor.png" v-else></img>
                </div>
                <div class="top_right">
                    <div class="top_right_top">
                        <div class="name f15 bold c-1 line-1">{{UserRecordEventList[0].operationUserName||'游客'}}</div>
                    </div>
                    <div class="m-t-5 f12 c_c0">来自{{UserRecordEventList[0].radarRecordOriginName || '平台' }}分享的名片</div>
                </div>
            </div>
            <div class="bottom c_333 f12 m-t-10">
                <el-button type="" size="mini" v-if="UserRecordEventList[0].isFriend">已是好友</el-button>
                <el-button type="primary" size="mini" @click.stop="AddFItem(UserRecordEventList[0],i)" v-else>申请好友</el-button>
                <el-button type="" size="mini" @click.stop="Consult(UserRecordEventList[0],i)">发消息</el-button>
                <el-button type="" size="mini" @click.stop="makePhoneCall(UserRecordEventList[0],i)">查看电话</el-button>
            </div>
        </template>
        <div class="items c_666 f15 m-t-30">
            <div class="item" :class="'items_'+i" v-for="(v,i) of UserRecordEventList" :key="i">
                <div class="dot"></div>
                <div class="date c-1 f14">{{timestampFormat(v.operationTime)}}</div>
                <div class="text f12 c_c0">
        <!--             <span style="color:red;">
            {{v.operationUserName}}
        </span> -->
                    <span v-if="v.radarRecordEnterpriseActionTypeCount">
                        第
                        <span class="c_2090ff">{{v.radarRecordEnterpriseActionTypeCount}} </span>
                        次
                    </span>
                    <span class="c_2090ff">{{types[v.radarRecordEnterpriseDoType]}} </span>
                    <span>{{v.radarRecordObjectName}}</span>
                    <span class="c_666" v-if="v.record">,{{v.record}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { elk } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "detail",
    components: {},
    data() {
        return {

            // statusBarHeight: getApp().globalData.statusBarHeight,
            serverImg: this.$oucy.serverImg + 'radar/',
            UserRecordEventList: [],
            hasMore: false,
            start: 0,
            userId: null,
            moduleId: null,
            type: null,
            requestUrl: '/client/elk/useroperation/queryRadar',
            types: {
                "0": "查看你的企业名片，请尽快把握商机",
                "1": "查询员工列表",
                "2": "查看你的员工名片",
                "3": "查询短视频列表",
                "4": "查看你的短视频",
                "5": "查看你的商品列表",
                "6": "查了你的商品",
                "7": "点赞你的企业名片",
                "8": "点赞员工名片",
                "9": "点赞短视频",
                "10": "取消点赞你的企业名片",
                "11": "取消点赞员工名片",
                "12": "取消点赞短视频",
                "13": "收藏企业名片",
                "14": "点赞员工名片",
                "15": "点赞短视频",
                "16": "收藏商品",
                "17": "评论你的企业名片",
                "18": "评论员工名片",
                "19": "评论短视频",
            },


        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.userId = this.$route.query.userId
        this.moduleId = this.$route.query.moduleId
        this.type = this.$route.query.type
        if(this.moduleId!=this.enterpriseId){
            this.$oucy.replace('/enter/radar')
        }else{
            this.getUserRecordList()
        }
    },
    methods: {
        // 雷达
        getUserRecordList() {
            elk.queryRadar({
                "start": this.start,
                "limit": 30,
                "enterpriseId": this.moduleId,
                "operationUserId": this.userId,
            }, null, true).then(res => {
                if (res) {
                    this.UserRecordEventList.push(...res.content || [])
                        ++this.start
                    this.hasMore = !res.last
                }
            }, err => {
                console.log(err)
            })
        },

        // 私信
        Consult(v) {

            this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, { targetUserId: v.operationUserId });
        },
        // 添加好友
        AddFItem(v) {
            friend.addFriend({ targetUserId: v.operationUserId }).then(res => {
                // this.dialogVisible=false
                this.$message('操作成功')
            })
        },
        // 打电话
        makePhoneCall(e) {
            let phone = e.operationUserPhone
            if (phone) {
                this.$alert(phone, '查看电话')

            } else {
                this.$message('当前用户没有绑定电话')

            }
        },
        // 格式化时间
        timestampFormat(dateTimeStamp) {
            if (!dateTimeStamp) return
            let timestamp = new Date(dateTimeStamp.replace(/-/g, '/')).getTime() / 1000

            function zeroize(num) {
                return (String(num).length == 1 ? '0' : '') + num;
            }

            let curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
            let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

            let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
            let tmDate = new Date(timestamp * 1000); // 参数时间戳转换成的日期对象

            let Y = tmDate.getFullYear(),
                m = tmDate.getMonth() + 1,
                d = tmDate.getDate();
            let H = tmDate.getHours(),
                i = tmDate.getMinutes(),
                s = tmDate.getSeconds();

            if (timestampDiff < 60) { // 一分钟以内
                return "刚刚";
            } else if (timestampDiff < 3600) { // 一小时前之内
                return Math.floor(timestampDiff / 60) + "分钟前";
            } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
                return '今天' + ' ' + zeroize(H) + ':' + zeroize(i);
            } else {
                let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
                if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                    return '昨天' + ' ' + zeroize(H) + ':' + zeroize(i);
                } else
                    // 和微信一样4天内的为周
                    if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() < d * 1 + 4) {
                        let w = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][tmDate.getDay()]
                        return w + ' ' + zeroize(H) + ':' + zeroize(i);
                    }
                /*else if ( curDate.getFullYear() == Y ) { // 年内
                            return  zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                        } */
                else {
                    return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                }
            }
        },

    }

}
</script>
<style scoped lang="less">
.detail {
    background: #fff;
    padding: 20px;
}

.items {
    padding: 30rpx 40rpx;
}

.item {
    position: relative;

    padding-left: 15px;
    margin-left: 5px;
    box-sizing: border-box;
    padding-bottom: 20px;

    &:after {
        content: " ";
        width: 1px;
        height: 100%;
        background: #EFEFEF;
        position: absolute;
        left: -1.5px;
        z-index: 1;
        top: 7px;
    }
}

.item:last-child {

    // background:#f00;
    // border-left:none;
    &:after {
        content: " ";
        width: 1px;
        height: 100%;
        background: none;
        position: absolute;
        left: 0;
        z-index: 1;
        top: 7px;
    }
}

.dot {
    position: absolute;
    z-index: 2;
    left: -5px;
    top: 5px;
    width: 10px;
    height: 10px;
    background: #F7F7F7;
    border: 1px solid #2090FF;
    border-radius: 50%;
}

.text {
    margin-top: 5px;
    background: #F5F8FF;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    position: relative;
    &:after {
        content: " ";
        position: absolute;
        left: 14px;
        top: -8px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #F5F8FF;
        border-left: 5px solid transparent;
    }
}

.top {
    display: flex;
    /*padding: 10px;*/
}

.top_right {
    // display: flex;
    padding-left: 15px;
    flex: 1;
}

.top_right_top {
    display: flex;
    justify-content: space-between;
}

.circle {
    border-radius: 100px;
}

.c_c0 {
    color: #AAAFB7;
}
.items_0 .dot{
    background: #2090FF;
}
.c_2090ff {
    color: #2090ff;
}
</style>